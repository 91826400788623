import { useEffect, useState } from 'react';
import { LocaleDateProps, LocaleDateVariant } from '../../types';
import { formatLocaleDate } from '../../utils';

/**
 * Component that displays a locale-customized date without interfering with React rehydration.
 *
 * WARNING: bypassing this component will result in "Error:  Text content does not match server-rendered HTML"
 * on the console for clients in other locales.  This component ensures that server-rendered text and the initial
 * clientside render are consistent, while also customizing for a user's locale in the rerender.
 */
export const LocaleDate = ({ date, locale, variant = LocaleDateVariant.shortMonthYear }: LocaleDateProps) => {
    // Serverside and first render will display an empty string ''.
    const [formattedDate, setFormattedDate] = useState('');

    useEffect(() => {
        // Clientside only, on second render: format to user's locale.
        setFormattedDate(formatLocaleDate(date, locale, variant));
    }, [date, locale, variant]);

    return formattedDate;
};

export default LocaleDate;
