import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    BarGradient,
    Box,
    DispersedSourceIcon,
    Grid,
    LocaleDateVariant,
    PlumeIcon,
    ProgressBar,
    Typography,
    useTheme
} from '@methanesat/ui-components';
import { centroid, polygon } from '@turf/turf';

import { getTargetEmissionsRange } from '../../../../../consts';
import { SHOW_SHAREABLE_FEATURE_LINK } from '../../../../../environmentVariables';
import { useGetHistoricalSamplesForTarget, useTranslate } from '../../../../../hooks';
import { selectPlatform, selectTargetDates } from '../../../../../reducers';
import {
    AreaEmissionsProducts,
    CaptureFeatureProperties,
    MethaneLayerIds,
    Platforms,
    STACCalendarData,
    UrlFeatureTypes
} from '../../../../../types';
import { analytics, getColorRangeColors, getFeatureLinkLabel, getTargetTotalsColors } from '../../../../../utils';
import {
    formatEmissions,
    formatPercentage,
    formatTotalEmissionsMinified,
    isNumber,
    roundUsingSigFigs
} from '../../../../../utils/numbers';
import { formatMeasurementTime } from '../../../../../utils/time';
import { DrawerHeader } from '../../Headers';
import { DataRowsModalBody, FeatureLink } from '../../Layout';
import { handleTimeSelectorDayClick, stacDatesToTimeSelector } from './helpers';

export interface TargetDrawerProps {
    properties: CaptureFeatureProperties | null;
    geometry: GeoJSON.Polygon;
    id: string;
}

/**
 * next/dynamic here allows us to use enableResizeObserver within the HorizontalScroll component.
 * enableResizeObserver helps with right scrolling issues within the drawer.
 */
const DynamicTimeSelector = dynamic(() => import('@methanesat/ui-components').then((module) => module.TimeSelector), {
    ssr: false,
    loading: () => <p>Loading...</p>
});

const useGetDrawerHeaders = (properties: TargetDrawerProps['properties']) => {
    const t = useTranslate();
    const platform = useSelector(selectPlatform);
    const {
        basin,
        location,
        target_id: targetId
    }: Partial<CaptureFeatureProperties> = properties as CaptureFeatureProperties;

    const title = t(`emissionsMapPage.mapModals.target.title.${platform}`, { targetId });

    const basinValue = basin || location;
    const msatSubtitle = basinValue
        ? basinValue.match(/basin/i)
            ? basinValue
            : t(`emissionsMapPage.mapModals.target.subtitle.${Platforms.MSAT}`, { basin: basinValue })
        : '';

    const subtitle =
        platform === Platforms.MSAT
            ? msatSubtitle
            : t(`emissionsMapPage.mapModals.target.subtitle.${platform}`, { targetId });

    return {
        title,
        subtitle
    };
};

export const TargetDrawer = ({ properties, id }: TargetDrawerProps) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const t = useTranslate();
    // analytics
    useEffect(() => {
        analytics.openTargetDetails({
            name: `${id}`
        });
    }, [id]);

    // mapping properties to new variable names
    const {
        location,
        basin,
        size_km2: sizeKm2,
        start_datetime: timeStart,
        end_datetime: timeEnd,
        net_total: totalKgHr,
        ps_count: pointSourceCount,
        ps_total: pointSourceTotalKgHr,
        target_id: targetId,
        coordinates: coordinates,
        collectionId: collectionId
    }: Partial<CaptureFeatureProperties> = properties as CaptureFeatureProperties;
    const layerId = MethaneLayerIds.targets;

    const targetDates = useSelector(selectTargetDates);

    const selectorDates = useGetHistoricalSamplesForTarget(targetId);
    const shouldShowTotalEmissions = isNumber(totalKgHr);
    const platform = useSelector(selectPlatform);

    // TODO: figure out properties
    const basinValue = basin || location;
    const featureLinkLabel = getFeatureLinkLabel(platform, id, basinValue);
    const hasLinkProps = collectionId && coordinates && timeEnd && featureLinkLabel;

    const { title, subtitle } = useGetDrawerHeaders(properties);

    const { MIN, MAX } = getTargetEmissionsRange(platform);

    const trackDayClick = (day: STACCalendarData, timestamp: string) => {
        analytics.clickTargetDrawerObservation({
            itemId: day.id,
            platform: day.platform,
            emissionRate: day.totalKgHr ?? NaN,
            label: timestamp
        });
    };

    const detailDataRows = [
        {
            label: t('emissionsMapPage.mapModals.target.dataTable.measurementTaken'),
            value: formatMeasurementTime(timeStart, timeEnd)
        },
        ...(isNumber(sizeKm2)
            ? [
                  {
                      label: t('emissionsMapPage.mapModals.target.dataTable.sizeLabel'),
                      value: roundUsingSigFigs(sizeKm2, 'km²')
                  }
              ]
            : []),
        {
            label: t('emissionsMapPage.mapModals.common.dataTable.locationLabel'),
            value: basinValue
        }
    ];

    const targetTotalsColors = getTargetTotalsColors(theme, true);
    const pointEmissionsIconColors = getColorRangeColors(theme, true, AreaEmissionsProducts.l4);

    const dispersedEmissionsValueLabel = totalKgHr
        ? t('emissionsMapPage.mapModals.target.emissionsPerHour', {
              kgHr: formatEmissions(totalKgHr - (pointSourceTotalKgHr || 0))
          })
        : t('emissionsMapPage.mapModals.target.pendingData.general');

    // TODO: add text to locales when needed
    const descriptionText = t(`emissionsMapPage.mapModals.target.description.${platform}`);
    return (
        <Grid container sx={{ '& > .MuiGrid-item:not(:last-child)': { marginBottom: 4.5 } }}>
            <Grid item xs={12}>
                <DrawerHeader title={title} subtitle={subtitle} />
            </Grid>
            {/* Total emissions visual */}
            <Grid item xs={12}>
                <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4">{`${t('emissionsMapPage.mapModals.target.totalEmissionsTitle')}`}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {shouldShowTotalEmissions ? (
                            <BarGradient
                                colors={targetTotalsColors}
                                min={MIN}
                                minLabel={formatTotalEmissionsMinified(MIN)}
                                max={MAX}
                                maxLabel={formatTotalEmissionsMinified(MAX)}
                                value={totalKgHr}
                                valueText={t('emissionsMapPage.mapModals.target.emissionsPerHour', {
                                    kgHr: formatEmissions(totalKgHr || 0)
                                })}
                            />
                        ) : (
                            <BarGradient valueText={t('emissionsMapPage.mapModals.target.pendingData.total')} />
                        )}
                    </Grid>
                    {descriptionText && (
                        <Grid item xs={12}>
                            <Typography variant="body1">{descriptionText}</Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            {/* Target emissions composition */}
            <Grid item xs={12}>
                <Grid container sx={{ '& > .MuiGrid-item:not(:last-child)': { marginBottom: 2 } }}>
                    {/* Title */}
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            {t('emissionsMapPage.mapModals.target.emissionComposition.title')}
                        </Typography>
                    </Grid>
                    {/* Icons & percents */}
                    <Grid item xs={12}>
                        <Grid container sx={{ '& > .MuiGrid-item:not(:last-child)': { marginBottom: 1 } }}>
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-end'
                                    }}
                                >
                                    {/* Dispersed sources icon & percent */}
                                    <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                                        <DispersedSourceIcon colors={pointEmissionsIconColors} />
                                        {totalKgHr && (
                                            <Typography variant="body2">
                                                {formatPercentage(totalKgHr - (pointSourceTotalKgHr || 0), totalKgHr)}%
                                            </Typography>
                                        )}
                                    </Box>
                                    {/* Distinct sources icon & percent */}
                                    <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                                        {totalKgHr && (
                                            <Typography variant="body2">
                                                {formatPercentage(pointSourceTotalKgHr || 0, totalKgHr)}%
                                            </Typography>
                                        )}
                                        <PlumeIcon sx={{ height: 24 }} colors={pointEmissionsIconColors} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                {/* 
                              progress bar: if we are missing the total, display an 'empty'/0% progress bar
                            */}
                                {totalKgHr ? (
                                    <ProgressBar
                                        percent={formatPercentage(totalKgHr - (pointSourceTotalKgHr || 0), totalKgHr)}
                                    />
                                ) : (
                                    <ProgressBar percent={0} />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* data table for progress bar */}
                    <Grid item xs={12} sx={{ '& .MuiTypography-root:not(:last-child)': { marginBottom: 0.5 } }}>
                        <Grid container flexDirection="row" justifyContent="space-between">
                            <Grid
                                item
                                xs
                                textAlign="start"
                                display="flex"
                                justifyContent="space-between"
                                flexDirection="column"
                            >
                                <Typography variant="body1" fontWeight={700} lineHeight="1">
                                    {t(`emissionsMapPage.mapModals.target.emissionComposition.areaEmissionsLabel`)}
                                </Typography>
                                <Typography variant="body2">{dispersedEmissionsValueLabel}</Typography>
                            </Grid>
                            <Grid
                                item
                                xs
                                textAlign="end"
                                display="flex"
                                justifyContent="space-between"
                                flexDirection="column"
                            >
                                <Typography variant="body1" fontWeight={700} lineHeight="1">
                                    {t(
                                        `emissionsMapPage.mapModals.target.emissionComposition.pointSourcesLabel.${pointSourceCount === 1 ? 'singular' : 'plural'}`,
                                        {
                                            pointSourceCount: pointSourceCount
                                        }
                                    )}
                                </Typography>
                                <Typography variant="body2">
                                    {t('emissionsMapPage.mapModals.target.emissionsPerHour', {
                                        kgHr: formatEmissions(pointSourceTotalKgHr || 0)
                                    })}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/** Historical Time Selector */}
            <Grid item xs={12}>
                <Grid container sx={{ '& > .MuiGrid-item:not(:last-child)': { marginBottom: 2 } }}>
                    <Grid item xs={12}>
                        <Typography display={'inline-block'} variant="h4">
                            {t('emissionsMapPage.mapModals.common.overTimeTitle')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DynamicTimeSelector
                            data-testid="time-selector-day-picker"
                            data={stacDatesToTimeSelector({
                                dates: selectorDates,
                                handleClick: (day: STACCalendarData) => {
                                    handleTimeSelectorDayClick({
                                        day,
                                        dispatch,
                                        targetId,
                                        layerId,
                                        trackDayClick
                                    });
                                }
                            })}
                            enableResizeObserver
                            variant={LocaleDateVariant.day}
                            selectedValue={timeStart}
                            boxHeight="50px"
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/* At a glance */}
            <Grid item xs={12}>
                <Grid container sx={{ '& > .MuiGrid-item:not(:last-child)': { marginBottom: 2 } }}>
                    <Grid item xs={12}>
                        <Typography variant="h4">{t('emissionsMapPage.mapModals.common.dataTableTitle')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DataRowsModalBody dataRows={detailDataRows} />
                    </Grid>
                </Grid>
            </Grid>

            {/* Shareable link to feature */}
            {SHOW_SHAREABLE_FEATURE_LINK && hasLinkProps && (
                <Grid item xs={12}>
                    <Grid container sx={{ '& > .MuiGrid-item:not(:last-child)': { marginBottom: 2 } }}>
                        {/* title */}
                        <Grid item xs={12}>
                            <Typography variant="h4">
                                {t(`emissionsMapPage.mapModals.linksToFeatures.title.${layerId}`)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FeatureLink
                                collectionId={collectionId}
                                coordinates={centroid(polygon([coordinates])).geometry.coordinates}
                                date={timeEnd}
                                featureType={UrlFeatureTypes.Target}
                                itemId={id}
                                label={featureLinkLabel}
                                layerId={layerId}
                                platform={platform}
                                targetDate={targetDates[targetId]}
                                targetId={targetId}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
