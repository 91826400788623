import dynamic from 'next/dynamic';
import { ConfigType } from 'dayjs';
import { Box, Grid, LocaleDateVariant, SxProps, TimeSelectorProps, Typography } from '@methanesat/ui-components';
import { DrawerHeader } from '../EmissionsPageMap/LayerDataDrawer/Headers';

const DynamicTimeSelector = dynamic(() => import('@methanesat/ui-components').then((module) => module.TimeSelector), {
    ssr: false,
    loading: () => <p>Loading...</p>
});

export const TargetSummaryLayout = ({
    data,
    emissions,
    emissionsTitle,
    overTimeTitle,
    sx,
    title,
    selectedValue
}: {
    data: TimeSelectorProps['data'];
    emissions: string;
    emissionsTitle: string;
    overTimeTitle: string;
    sx?: SxProps;
    title: string;
    selectedValue: ConfigType;
}) => {
    return (
        <Box
            height="100%"
            sx={[
                {
                    width: 'fit-content',
                    maxWidth: '100% !important'
                },
                ...(Array.isArray(sx) ? sx : [sx])
            ]}
        >
            <Grid container flexDirection="column" maxWidth="inherit">
                <Grid item marginBottom={2}>
                    <DrawerHeader title={title} subtitle={`${emissionsTitle}: ${emissions}`} />
                </Grid>
                <Grid item marginBottom={1}>
                    <Typography variant="h4">{overTimeTitle}</Typography>
                </Grid>
                <Grid item maxWidth="inherit">
                    <DynamicTimeSelector
                        data-testid="time-selector-day-mini-picker"
                        data={data}
                        enableResizeObserver
                        variant={LocaleDateVariant.day}
                        boxHeight="50px"
                        selectedValue={selectedValue}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
