import isEqual from 'lodash/isEqual';
import { GetStaticPathsResult, GetStaticPropsContext, GetStaticPropsResult } from 'next';
import { ReactElement, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Alert,
    AlertBadge,
    AlertSeverity,
    Box,
    Button,
    DatePicker,
    DownloadIcon,
    Grid,
    HelpOutlineIcon,
    LocaleDateVariant,
    NotificationIcon,
    SettingsIcon,
    Snackbar,
    SpeedDialAction,
    styled,
    Typography
} from '@methanesat/ui-components';

import { DownloadDataDrawer } from '../../../components/DownloadDataDrawer';
import { MiniLegend } from '../../../components/EmissionsMapLegend';
import { EmissionsMapSpeedDial } from '../../../components/EmissionsMapSpeedDial';
import { EmissionsPageMap } from '../../../components/EmissionsPageMap';
import { LayerDataDrawer, MultipleLayerDataDrawer } from '../../../components/EmissionsPageMap/LayerDataDrawer';
import { EmissionsPageZoomControls } from '../../../components/EmissionsPageZoomControls';
import Head from '../../../components/Head';
import Header from '../../../components/Header';
import { IntroDrawer, IntroTooltip } from '../../../components/IntroDrawer';
import {
    EmissionsLayerControl,
    InfrastructureOperatorFilter,
    MapControlsDrawer,
    MapLayerControl
} from '../../../components/MapControls';
import { NoDataInfoDrawer } from '../../../components/NoDataInfoDrawer';
import { NotificationsDrawer } from '../../../components/NotificationsDrawer';
import { FloatingTargetSummary } from '../../../components/FloatingTargetSummary';
import { getGlobalDateOptions, ONE_HUNDRED_VH_STYLING } from '../../../consts';
import {
    checkEmissionsMapEnv,
    GOOGLE_MAPS_API_KEY,
    USE_GLOBAL_DATE_PICKER,
    USE_SPEED_DIAL
} from '../../../environmentVariables';
import {
    CapturePlusCount,
    useAppSelector,
    useGetCapturesWithinGlobalTimeRange,
    useGetCapturesWithinViewport,
    useGetHistoricalSamplesForTarget,
    useTranslate
} from '../../../hooks';
import { useAreaEmissionConfig, usePlumeEmissionRateConfig } from '../../../hooks/pages/emissions-map';
import {
    addEmissionsMapNotification,
    addMultipleMapFeatureHighlights,
    addOneMapFeatureHighlight,
    drawerStateReducer,
    initialDrawerState,
    removeAllMapFeatureHighlights,
    resetOgiNearPlumes,
    selectAreaEmissionsWithinZoom,
    selectEmissionsMapNotifications,
    selectEmissionsMapStyle,
    selectFlooredZoom,
    selectGlobalDate,
    selectHighlightedFeature,
    selectHighlightedLayerId,
    selectInfrastructureEnabled,
    selectInfrastructureWithinZoom,
    selectPlumeFluxWithinZoom,
    setOGILayerConfig,
    setEmissionsMapStyle,
    setGlobalDate,
    setL4LayerConfigs,
    updateEmissionsMapNotification,
    resetSelectedFeatureParams,
    selectPlatform,
    selectActiveCapture,
    clearActiveCapture,
    tooltipSlice
} from '../../../reducers';
import { RootState } from '../../../store';
import {
    areNotificationsLoading,
    CommonInfrastructureLayerConfig,
    HighlightedObject,
    isBitmapInfo,
    isCaptureFeature,
    isClusteredPointOGITileFeature,
    isGeoJsonFeature,
    MethaneDiffuseRangeFilterKey,
    MethaneDistinctRangeFilterKey,
    NotificationMessage,
    OGILayerIds,
    PickInfoToPrioritize,
    Platforms,
    SupportedLocaleObject,
    STACCalendarData,
    UpdatableEmissionRateLayerConfig
} from '../../../types';
import {
    addLanguageAndDictionaryToProps,
    addLanguageToPathParams,
    analytics,
    ErrorBoundary,
    formatEmissions,
    getFeatureId,
    getFromLocalStorage,
    handleErrorBoundaryError,
    setInLocalStorage
} from '../../../utils';
import { isWithinDrawerHighlightZoomBounds } from '../../../consts';
import {
    stacDatesToTimeSelector,
    handleTimeSelectorDayClick
} from '../../../components/EmissionsPageMap/LayerDataDrawer/DataSpecific/EmissionsDrawers/helpers';

/**
 * Outer container element for the emissions map.
 */
const PageContainer = styled('div')({
    flexDirection: 'column',
    display: 'flex',
    height: ONE_HUNDRED_VH_STYLING
});

/**
 * Emissions map gives context to where methane plumes are, when they were detected, how much is being emitted,
 * and who might be emitting them.
 */
function EmissionsMap(): ReactElement {
    const t = useTranslate();
    const dispatch = useDispatch();

    const [drawerState, drawerDispatch] = useReducer(drawerStateReducer, initialDrawerState);

    // mobile banner state
    const [isBannerOpen, setIsBannerOpen] = useState(true);

    const handleBannerClose = () => setIsBannerOpen(false);

    const [drawerWidth, setDrawerWidth] = useState(0);
    const [layerDataInfo, setLayerDataInfo] = useState<PickInfoToPrioritize | PickInfoToPrioritize[] | null>(null);

    const allDrawersClosed = useMemo(() => {
        return Object.values(drawerState).reduce((allDrawersClosed, currentDrawerOpen) => {
            return allDrawersClosed && !currentDrawerOpen;
        }, true);
    }, [drawerState]);

    const mapStyle = useSelector(selectEmissionsMapStyle);
    const isMapInteractive = useSelector(
        (state: RootState) => state.pages.emissions.mapInterface.gestureHandling !== 'none'
    );

    const globalDate = useSelector(selectGlobalDate);

    // intro halo tooltips
    const isTooltipVisible = useAppSelector((state) => state.tooltip.visible);

    // notification handling
    const notifications = useSelector(selectEmissionsMapNotifications);
    function closeSnackbar(message: string) {
        dispatch(updateEmissionsMapNotification({ message, updates: { snackbarOpen: false } }));
    }
    const localStorageNotifications = useMemo(() => {
        return getFromLocalStorage('notifications') as NotificationMessage[];
    }, []);

    const howToNotifications: NotificationMessage[] = [
        {
            title: t('emissionsMapPage.gettingStarted.welcome.title'),
            message: t('emissionsMapPage.gettingStarted.welcome.message'),
            notificationVisible: true,
            read: false,
            severity: AlertSeverity.info,
            store: true
        }
    ];

    useEffect(() => {
        if (!localStorageNotifications || !localStorageNotifications.length) {
            howToNotifications.forEach((notification) => dispatch(addEmissionsMapNotification(notification)));
        } else {
            localStorageNotifications.forEach((notification) => dispatch(addEmissionsMapNotification(notification)));
        }
    }, [localStorageNotifications]);

    const alertBadgeProps = useMemo(() => {
        if (areNotificationsLoading(notifications)) return {};
        return {
            badgeContent: notifications.filter((n) => !n.read).length,
            severity: AlertSeverity.error
        };
    }, [notifications]);

    // methane layer configs
    const areaEmissionConfig = useAreaEmissionConfig();
    const distinctL4Config = usePlumeEmissionRateConfig();

    // infrastructure layer properties
    const infrastructureEnabled = useSelector(selectInfrastructureEnabled);
    const infrastructureWithinZoom = useSelector(selectInfrastructureWithinZoom);
    const areaFluxWithinZoom = useSelector(selectAreaEmissionsWithinZoom);
    const plumeFluxWithinZoom = useSelector(selectPlumeFluxWithinZoom);
    const ogiNearPlumesEnabled = useSelector(
        (state: RootState) => state.pages.emissions.layers.ogiLayers.ogiNearPlumesEnabled
    );

    // highlighted infrastructure object
    const highlightedFeature = useSelector(selectHighlightedFeature);
    const prevHighlight = useRef<HighlightedObject>(highlightedFeature);

    const fetchingDrawerInfo = useSelector(
        (state: RootState) => state.pages.emissions.selectedFeature.fetchingDrawerInfo
    );

    const platform = useSelector(selectPlatform);
    const prevPlatform = useRef<string>(platform);

    const GLOBAL_DATE_OPTIONS = getGlobalDateOptions(platform);

    const activeCapture = useSelector(selectActiveCapture);
    const { featureList, isFetching } = useGetCapturesWithinGlobalTimeRange();
    const visibleCaptures = useGetCapturesWithinViewport(featureList);
    const [floatingSummaryCapture, setFloatingSummaryCapture] = useState<CapturePlusCount | null>(null);
    const selectorDates = useGetHistoricalSamplesForTarget(floatingSummaryCapture?.properties.target_id);

    // Update `floatingSummaryCapture` to be the active target (if selected through temporal navigation)
    // or the only visible target. If no target is active and there are none or multiple in view, reset
    // the active capture and hide the floating summary.
    useEffect(() => {
        if (isFetching) return;
        let capture = null;

        // Check if a capture has been activated through temporal navigation
        if (activeCapture) {
            const activeCaptureInView = visibleCaptures.find((c) => c.properties.target_id === activeCapture.targetId);
            if (activeCaptureInView) capture = activeCaptureInView;
            else {
                dispatch(clearActiveCapture());
            }
        }
        // Otherwise, use the singular capture in the viewport if present
        else if (visibleCaptures.length === 1) capture = visibleCaptures[0];

        setFloatingSummaryCapture(capture);
    }, [activeCapture, visibleCaptures, dispatch, isFetching]);

    const trackDayClick = (day: STACCalendarData, timestampString: string) => {
        analytics.clickFloatingSummaryObservation({
            itemId: day.id,
            platform: day.platform,
            emissionRate: day.totalKgHr ?? NaN,
            label: timestampString
        });
    };

    const floatingTargetSummaryDates =
        floatingSummaryCapture &&
        stacDatesToTimeSelector({
            dates: selectorDates,
            handleClick: (day: STACCalendarData) => {
                const targetId = floatingSummaryCapture.properties.target_id;
                handleTimeSelectorDayClick({ day, dispatch, targetId, trackDayClick });
            }
        });

    /**
     * Closes a side drawer.
     */
    const closeDrawer = useCallback(() => {
        dispatch(removeAllMapFeatureHighlights());
        //Remove selected feature parameters from the URL
        dispatch(resetSelectedFeatureParams());
        drawerDispatch({ type: 'close' });
    }, [dispatch]);

    /**
     * Un-highlight OGI feature and close the drawer if:
     * highlighted object has the same id but different properties (changed cluster truthyness or count)
     * highlighted object is now null or undefined
     */
    const highlightedLayerId = useSelector(selectHighlightedLayerId);
    const zoom = useSelector(selectFlooredZoom);
    useEffect(() => {
        let sameId = false;
        let sameProperties = false;
        if (
            (isGeoJsonFeature(highlightedFeature) && isGeoJsonFeature(prevHighlight.current)) ||
            (isCaptureFeature(highlightedFeature) && isCaptureFeature(prevHighlight.current))
        ) {
            // both are geojson or both are captures
            sameId = highlightedFeature.id === prevHighlight.current?.id;

            if (
                isClusteredPointOGITileFeature(highlightedFeature) ||
                isClusteredPointOGITileFeature(prevHighlight.current)
            ) {
                // must check clustered OGI points differently because their ids
                // are assigned semi-randomly by tippecanoe at the time of tile-building
                sameProperties = isEqual(highlightedFeature.properties, prevHighlight.current?.properties);
            } else {
                // assume everything else with the same id is the same actual feature
                sameProperties = sameId;
            }
        } else if (isBitmapInfo(highlightedFeature) && isBitmapInfo(prevHighlight.current)) {
            // both are bitmap
            sameId = highlightedFeature.pixel === prevHighlight.current.pixel;
            sameProperties = isEqual(highlightedFeature, prevHighlight.current);
        }

        const highlightIsNothing = highlightedFeature === undefined || highlightedFeature === null;
        const highlightWasNothing = prevHighlight.current === undefined || prevHighlight.current === null;

        // plumes are geojson features but do not have ids. we should leave
        const noHighlightedFeatureId = highlightedFeature && (!('id' in highlightedFeature) || !highlightedFeature.id);

        const changedPlatforms = platform !== prevPlatform.current;
        let shouldLeaveDataDrawerOpen = false;

        // leave drawer open if platform is NOT changed and there is a highlighted feature and...
        if (!changedPlatforms && highlightedFeature) {
            // ...AND one of the following
            if (highlightWasNothing) {
                // there was no highlight previously
                shouldLeaveDataDrawerOpen = true;
            } else if (sameId && sameProperties) {
                // the highlight is unchanged
                shouldLeaveDataDrawerOpen = true;
            } else if (!sameId) {
                // the highlight has changed - user clicked a new feature
                shouldLeaveDataDrawerOpen = true;
            } else if (noHighlightedFeatureId && !sameProperties) {
                /** highlight does not have an id and properties are new -
                 * This allows leaving the drawer open when a feature without an id - a plume - is
                 * clicked for the first time. In that case, no other conditions would be met.
                 */
                shouldLeaveDataDrawerOpen = true;
            }
        }
        const dataDrawerOpen = drawerState.layerData || drawerState.noData;
        if (
            !fetchingDrawerInfo &&
            (highlightIsNothing ||
                !shouldLeaveDataDrawerOpen ||
                !isWithinDrawerHighlightZoomBounds(zoom, highlightedLayerId, highlightedFeature)) &&
            dataDrawerOpen
        ) {
            closeDrawer();
        }
        prevHighlight.current = highlightedFeature;
        prevPlatform.current = platform;
    }, [highlightedFeature, highlightedLayerId, zoom, globalDate, platform, fetchingDrawerInfo, closeDrawer]);

    /**
     * Opens a side drawer (e.g. for customizing map settings).
     */
    const openDrawer = (selectedDrawer: keyof typeof initialDrawerState) => {
        drawerDispatch({ type: 'open', id: selectedDrawer });
        if (selectedDrawer !== 'layerData') dispatch(removeAllMapFeatureHighlights());
        switch (selectedDrawer) {
            case 'download':
                return analytics.openDownload();
            case 'settings':
                return analytics.openSettings();
            case 'notifications':
                return analytics.openNotifications({
                    count: alertBadgeProps.badgeContent || 0,
                    severity: alertBadgeProps.severity
                });
        }
    };

    /** Opens the full map legend. */
    const openLegend = (source: 'mini legend' | 'speed dial' | 'header' | 'on-load') => {
        openDrawer('legend');
        return analytics.openLegend({ source });
    };

    /** Handles when a user taps on an object on the map. */
    const handleMapInfo = (info: PickInfoToPrioritize | PickInfoToPrioritize[] | undefined, clickedNoData: boolean) => {
        if (!isMapInteractive) {
            return;
        } else if (info && Array.isArray(info)) {
            // handle multiple map feature highlights and drawer infos
            dispatch(
                addMultipleMapFeatureHighlights({
                    layerId: info[0].layer.id,
                    features: info.map((oneInfo) => ({
                        index: oneInfo.index,
                        objectId: oneInfo.object.id
                    }))
                })
            );
            setLayerDataInfo(info);
            openDrawer('layerData');
        } else if (info && info.bitmap) {
            // handle info from raster layer
            dispatch(removeAllMapFeatureHighlights());
            setLayerDataInfo(info);
            openDrawer('layerData');
            dispatch(
                addOneMapFeatureHighlight({
                    index: info.index,
                    objectId: info.bitmap.pixel.join('-'),
                    layerId: info.layer.id,
                    object: info.bitmap,
                    coordinate: info.coordinate
                })
            );
        } else if (info && typeof info.index === 'undefined') {
            // handle info fetched from the URL, in which case fields like `index` and `bitmap` are not present
            setLayerDataInfo(info);
            openDrawer('layerData');
            dispatch(
                addOneMapFeatureHighlight({
                    objectId: getFeatureId(info),
                    layerId: info.layer.id,
                    object: info.object,
                    ...(info.coordinate && { coordinate: info.coordinate })
                })
            );
        } else if (info) {
            // handle info from all other layers
            dispatch(
                addOneMapFeatureHighlight({
                    index: info.index,
                    objectId: getFeatureId(info),
                    layerId: info.layer.id,
                    object: info.object
                })
            );
            setLayerDataInfo(info);
            openDrawer('layerData');
        } else if (clickedNoData) {
            dispatch(removeAllMapFeatureHighlights());
            openDrawer('noData');
            analytics.openNoData();
        } else {
            openDrawer('noData');
            analytics.openNoData();
        }
    };

    /**
     * Handles when a user changes from one map style to another (e.g. to satellite style).
     */
    const handleMapStyleChange = (value: string) => {
        dispatch(setEmissionsMapStyle(value));
    };

    // this controls pipelines and point infrastructure
    const updateAllInfrastructureLayers = ({ enabled }: Required<Pick<CommonInfrastructureLayerConfig, 'enabled'>>) => {
        dispatch(
            setOGILayerConfig({
                layerId: OGILayerIds.pointInfrastructure,
                layerUpdates: { enabled }
            })
        );
        dispatch(
            setOGILayerConfig({
                layerId: OGILayerIds.pipelines,
                layerUpdates: { enabled }
            })
        );
        analytics.toggleOilAndGasData({ visible: enabled });
    };

    const updateAllL4Layers = (layerUpdates: UpdatableEmissionRateLayerConfig) => {
        dispatch(setL4LayerConfigs(layerUpdates));

        // Analytics logic below
        if (layerUpdates.filtersPlume !== undefined) {
            const filtersPlume = layerUpdates.filtersPlume;
            const labels = t(`emissionsMapPage.mapControls.methaneFilters.plume`);
            // This if statement is a hack because the return type of the t function
            // is string
            if (typeof labels === 'object') {
                let visibleLayers: string[] = [];
                let invisibleLayers: string[] = [];

                (Object.keys(labels) as MethaneDistinctRangeFilterKey[]).forEach((label) => {
                    if (filtersPlume.includes(label)) {
                        visibleLayers = [...visibleLayers, labels[label]];
                    } else {
                        invisibleLayers = [...invisibleLayers, labels[label]];
                    }
                });

                analytics.filterPlumeEmissions({ visibleLayers, invisibleLayers });
            }
        } else if (layerUpdates.filtersArea !== undefined) {
            const filtersArea = layerUpdates.filtersArea;
            const labels = t(`emissionsMapPage.mapControls.methaneFilters.area`);
            // This if statement is a hack because the return type of the t function
            // is string
            if (typeof labels === 'object') {
                let visibleLayers: string[] = [];
                let invisibleLayers: string[] = [];

                (Object.keys(labels) as MethaneDiffuseRangeFilterKey[]).forEach((label) => {
                    if (filtersArea.includes(label)) {
                        visibleLayers = [...visibleLayers, labels[label]];
                    } else {
                        invisibleLayers = [...invisibleLayers, labels[label]];
                    }
                });

                analytics.filterAreaEmissions({ visibleLayers, invisibleLayers });
            }
        }
    };

    const floatingSummaryCloseProps = useMemo(() => {
        return {
            showCloseButton: visibleCaptures.length > 1,
            onClose: () => {
                dispatch(clearActiveCapture());
                setFloatingSummaryCapture(null);
            }
        };
    }, [dispatch, visibleCaptures.length]);

    /**
     * Toggles on all emissions layers.
     */
    const toggleEmissionsLayers = ({ enabled }: Required<Pick<UpdatableEmissionRateLayerConfig, 'enabled'>>) => {
        updateAllL4Layers({ enabled });
        analytics.toggleAreaEmissionData({ visible: enabled });
        analytics.togglePlumeEmissionData({ visible: enabled });
    };

    return (
        <PageContainer>
            <Head title={`${t('emissionsMapPage.title')} - MethaneSAT`} />
            <Header
                onOpenLegend={() => drawerDispatch({ type: 'open', id: 'legend' })}
                isLegendOpen={drawerState.legend}
            />
            {/** Dismiss-able banner for mobile screens*/}
            <Snackbar
                open={isBannerOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{
                    top: '60px',
                    display: {
                        sm: 'none',
                        xs: 'flex'
                    }
                }}
            >
                <Alert
                    variant="filled"
                    severity="warning"
                    action={
                        <Button
                            data-testid="Liehk39faZ_y_r1XVNGXf"
                            color="inherit"
                            size="small"
                            onClick={handleBannerClose}
                        >
                            {t('header.dismiss')}
                        </Button>
                    }
                    sx={{
                        width: '100%'
                    }}
                >
                    {t('header.viewOnDesktop')}
                </Alert>
            </Snackbar>
            <Box
                component="main"
                sx={{
                    position: 'relative',
                    flex: 1
                }}
            >
                <ErrorBoundary
                    onError={handleErrorBoundaryError('EmissionsPageMap')}
                    fallbackRender={() => {
                        return (
                            <Grid
                                container
                                direction="column"
                                sx={{ height: '100%', background: 'rgba(0,0,0,0.2)', padding: 5 }}
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item xs="auto">
                                    <Typography component="p" variant="h1" sx={{ margin: 0 }}>
                                        Sorry! There was a problem loading the map. Please refresh the page and try
                                        again.
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Button
                                        data-testid="map-error-refresh"
                                        href="/en/emissions-map"
                                        variant="contained"
                                    >
                                        Refresh
                                    </Button>
                                </Grid>
                            </Grid>
                        );
                    }}
                >
                    <EmissionsPageMap
                        data-testid="emissions-page-map"
                        leftWidthOffset={0}
                        handleMapInfo={handleMapInfo}
                        rightWidthOffset={-drawerWidth}
                    />
                </ErrorBoundary>
                <Box
                    sx={(theme) => ({
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        paddingTop: theme.spacing(1),
                        paddingX: theme.spacing(2),
                        // Specifiying none here means that none of the children of this element
                        // will receive on click events. This allows us to to click through to
                        // the map
                        pointerEvents: 'none',
                        width: '100%'
                    })}
                >
                    {isTooltipVisible && <IntroTooltip />}
                    <MiniLegend
                        onOpenLegend={() => openLegend('mini legend')}
                        rightWidthOffset={allDrawersClosed ? 0 : -drawerWidth}
                    />
                    {!areNotificationsLoading(notifications) &&
                        notifications.map(
                            (notification) =>
                                notification.isSnackbar && (
                                    <Snackbar
                                        key={notification.message}
                                        open={notification.snackbarOpen}
                                        autoHideDuration={4000}
                                        onClose={() => closeSnackbar(notification.message)}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                        sx={{
                                            position: 'relative',
                                            top: '0px !important',
                                            left: '0px !important',
                                            marginTop: 2,
                                            // We want to makes sure that this component and its
                                            // children can be clickable with pointerEvents auto
                                            pointerEvents: 'auto'
                                        }}
                                    >
                                        <Alert
                                            severity={notification.severity}
                                            variant="filled"
                                            onClose={() => closeSnackbar(notification.message)}
                                        >
                                            {notification.message}
                                        </Alert>
                                    </Snackbar>
                                )
                        )}
                </Box>
                {/* speed dial, controls drawers except map feature info */}
                {USE_SPEED_DIAL && (
                    <EmissionsMapSpeedDial
                        AlertBadgeProps={alertBadgeProps}
                        actions={[
                            <SpeedDialAction
                                data-testid="emissions-map-speed-dial-action-legend"
                                key={'legend'}
                                icon={<HelpOutlineIcon />}
                                tooltipTitle={t('emissionsMapPage.speedDial.legend')}
                                onClick={() => openLegend('speed dial')}
                            />,
                            <SpeedDialAction
                                data-testid="emissions-map-speed-dial-action-settings"
                                key={'settings'}
                                icon={<SettingsIcon />}
                                tooltipTitle={t('emissionsMapPage.speedDial.settings')}
                                onClick={() => openDrawer('settings')}
                            />,
                            <SpeedDialAction
                                data-testid="emissions-map-speed-dial-action-download"
                                key={'download'}
                                icon={<DownloadIcon />}
                                tooltipTitle={t('emissionsMapPage.speedDial.download')}
                                onClick={() => openDrawer('download')}
                            />,
                            <SpeedDialAction
                                data-testid="emissions-map-speed-dial-action-alerts"
                                key={'alerts'}
                                icon={
                                    <AlertBadge {...alertBadgeProps}>
                                        <NotificationIcon />
                                    </AlertBadge>
                                }
                                tooltipTitle={t('emissionsMapPage.speedDial.notifications')}
                                onClick={() => openDrawer('notifications')}
                            />
                        ]}
                    />
                )}
                {/* all map drawers */}
                <MapControlsDrawer
                    open={drawerState.settings}
                    onClose={closeDrawer}
                    // Hide the map styling when Google Maps is used until
                    // we make custom Google Maps styles.
                    StyleButtonProps={
                        GOOGLE_MAPS_API_KEY
                            ? undefined
                            : {
                                  onChange: (ev) => handleMapStyleChange(ev.target.value),
                                  mapStyle
                              }
                    }
                    showAppBar="smUp"
                    onWidth={setDrawerWidth}
                >
                    <MapLayerControl
                        label={t('emissionsMapPage.mapControls.methaneFilters.title')}
                        setLayerConfig={toggleEmissionsLayers}
                        checked={areaEmissionConfig.enabled && distinctL4Config.enabled}
                        disabled={!areaFluxWithinZoom && !plumeFluxWithinZoom}
                        indeterminate={areaEmissionConfig.enabled !== distinctL4Config.enabled}
                        expandable={true}
                    >
                        <EmissionsLayerControl
                            id="emissions"
                            checked={true}
                            filtersArea={areaEmissionConfig.filtersArea}
                            filtersPlume={distinctL4Config.filtersPlume}
                        />
                    </MapLayerControl>
                    <MapLayerControl
                        defaultExpanded={true}
                        checked={infrastructureEnabled}
                        disabled={!infrastructureWithinZoom}
                        expandable={true}
                        label={t('emissionsMapPage.mapControls.infrastructureTitle')}
                        setLayerConfig={updateAllInfrastructureLayers}
                    >
                        <ErrorBoundary
                            onError={handleErrorBoundaryError('emissions-map page - OGI MapLayerControl')}
                            fallbackRender={({ resetErrorBoundary }) => {
                                return (
                                    <Alert
                                        action={
                                            <Button
                                                color="inherit"
                                                data-testid="filter-error-boundary-reset"
                                                onClick={resetErrorBoundary}
                                                size="small"
                                            >
                                                {t('emissionsMapPage.error.retry')}
                                            </Button>
                                        }
                                        severity="error"
                                    >
                                        {t('emissionsMapPage.error.general')}
                                    </Alert>
                                );
                            }}
                        >
                            <InfrastructureOperatorFilter rightWidthOffset={-drawerWidth} />
                        </ErrorBoundary>
                    </MapLayerControl>
                </MapControlsDrawer>
                <DownloadDataDrawer open={drawerState.download} onClose={closeDrawer} onWidth={setDrawerWidth} />
                <NotificationsDrawer
                    open={drawerState.notifications}
                    onClose={closeDrawer}
                    notifications={notifications}
                    onWidth={setDrawerWidth}
                />
                <NoDataInfoDrawer open={drawerState.noData} onClose={closeDrawer} onWidth={setDrawerWidth} />
                <IntroDrawer
                    data-testid="emissions-map-legend"
                    open={drawerState.legend}
                    onClose={(ev: Event) => {
                        ev.stopPropagation();
                        // prevent intro drawer from showing on load again
                        setInLocalStorage('introDismissed', true);
                        // hide IntroTooltip
                        dispatch(tooltipSlice.actions.hide());
                        // close drawer
                        closeDrawer();
                    }}
                    onWidth={setDrawerWidth}
                />
                {layerDataInfo && !Array.isArray(layerDataInfo) && (
                    <LayerDataDrawer
                        data-testid="layer-data-drawer"
                        info={layerDataInfo}
                        onClose={() => {
                            if (ogiNearPlumesEnabled) {
                                dispatch(resetOgiNearPlumes());
                            }
                            closeDrawer();
                        }}
                        open={drawerState.layerData && !!layerDataInfo}
                        onWidth={setDrawerWidth}
                    />
                )}
                {layerDataInfo && Array.isArray(layerDataInfo) && layerDataInfo.length && (
                    <MultipleLayerDataDrawer
                        data-testid="layer-data-drawer"
                        infos={layerDataInfo}
                        onClose={() => {
                            if (ogiNearPlumesEnabled) {
                                dispatch(resetOgiNearPlumes());
                            }
                            closeDrawer();
                        }}
                        open={drawerState.layerData && !!layerDataInfo}
                        onWidth={setDrawerWidth}
                    />
                )}
                {allDrawersClosed && floatingTargetSummaryDates && (
                    <FloatingTargetSummary
                        title={t(`emissionsMapPage.mapModals.target.floatingTargetSummaryTitle.${platform}`, {
                            targetId: floatingSummaryCapture.properties.target_id
                        })}
                        data={floatingTargetSummaryDates}
                        emissions={
                            floatingSummaryCapture.properties.net_total
                                ? t('emissionsMapPage.mapModals.target.emissionsPerHour', {
                                      kgHr: formatEmissions(floatingSummaryCapture.properties.net_total)
                                  })
                                : t('emissionsMapPage.mapModals.target.pendingData.total')
                        }
                        emissionsTitle={t('emissionsMapPage.mapModals.target.totalEmissionsTitle')}
                        overTimeTitle={t('emissionsMapPage.mapModals.common.overTimeTitle')}
                        closeProps={floatingSummaryCloseProps}
                        selectedValue={floatingSummaryCapture.properties.datetime}
                    />
                )}
                {/* zoom controls */}
                {isMapInteractive && (
                    <Grid
                        container
                        flexDirection="row"
                        alignItems="center"
                        position="absolute"
                        bottom={0}
                        rowSpacing={2}
                        columnSpacing={2}
                        sx={(theme) => ({
                            paddingBottom: theme.spacing(4),
                            paddingX: theme.spacing(2),
                            // Specifiying none here means that none of the children of this element
                            // will receive on click events. This allows us to to click through to
                            // the map
                            pointerEvents: 'none',
                            /** Horizontally scroll elements.
                             * pointerEvents is auto for the elements that are not grids.
                             * This allows the user to click through the row containing the dates.
                             */
                            '& :not(.MuiGrid-root)': { pointerEvents: 'auto' }
                        })}
                    >
                        <Grid item xs="auto">
                            <EmissionsPageZoomControls />
                        </Grid>
                        {USE_GLOBAL_DATE_PICKER && (
                            <Grid
                                item
                                xs="auto"
                                sx={(theme) => ({
                                    maxWidth: {
                                        xs: '100%',
                                        // small breakpoint (600px) minus the left padding
                                        // of this grid item's container (16px)
                                        sm: `calc(600px - ${theme.spacing(4)}) !important`,
                                        // more magic numbers: max width of the target summary panel,
                                        // the width of the zoom buttons, and the padding between and around
                                        // these elements
                                        md: `calc(100vw - 500px - 34px - ${theme.spacing(8)})`,
                                        lg: '700px !important'
                                    }
                                })}
                            >
                                <DatePicker
                                    data-testid="global-date-picker"
                                    // sending down the platform value as a key so that TimeSelector refs properly upate
                                    platform={platform}
                                    onClick={(date: number) => {
                                        analytics.chooseGlobalDate({ label: `${date}` });
                                        // If a feature has been clicked on and its drawer is open, close it when the date changes from the picker
                                        if (highlightedFeature) {
                                            closeDrawer();
                                        }
                                        dispatch(setGlobalDate(date));
                                    }}
                                    onChange={(ev) => {
                                        analytics.chooseGlobalDate({ label: `${ev.target.value}` });
                                        dispatch(setGlobalDate(ev.target.value as number));
                                    }}
                                    options={GLOBAL_DATE_OPTIONS}
                                    promptText={t('emissionsMapPage.datePicker.prompt')}
                                    value={globalDate}
                                    variant={
                                        platform === Platforms.MSAT
                                            ? LocaleDateVariant.quarterly
                                            : LocaleDateVariant.shortMonthYear
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
            </Box>
        </PageContainer>
    );
}

/**
 * Used by Next.js at build time.
 * See https://nextjs.org/docs/pages/building-your-application/data-fetching/get-static-props
 */
export async function getStaticProps(
    context: GetStaticPropsContext
): Promise<GetStaticPropsResult<SupportedLocaleObject>> {
    const props = await addLanguageAndDictionaryToProps(context.params?.language, {}, { page: 'emissionsMap' });

    checkEmissionsMapEnv();

    return {
        props
    };
}

/**
 * Used by Next.js at build time.
 * See https://nextjs.org/docs/pages/building-your-application/data-fetching/get-static-paths
 */
export async function getStaticPaths(): Promise<GetStaticPathsResult> {
    return {
        paths: addLanguageToPathParams(),
        fallback: false
    };
}

export default EmissionsMap;
