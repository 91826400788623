import { MethaneLayerIds, STACCalendarData, UrlFeatureTypes } from '../../../../../types';
import { formatDateForUrl, getDayEnd } from '../../../../../utils';
import { getFormattedDate } from '@methanesat/ui-components';
import { centroid, polygon } from '@turf/turf';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import {
    removeAllMapFeatureHighlights,
    resetSelectedFeatureParams,
    setActiveCapture,
    setSelectedFeatureParams,
    setTargetDate
} from '../../../../../reducers';
import { log } from '@methanesat/log';

export interface handleDayClickProps {
    day: STACCalendarData;
    dispatch: Dispatch<UnknownAction>;
    targetId: string;
    trackDayClick: (day: STACCalendarData, timestampString: string) => void;
    layerId?: MethaneLayerIds;
}

interface stacDatesToTimeSelectorProps {
    dates: STACCalendarData[] | null;
    handleClick: (arg0: STACCalendarData) => void;
}

/**
 * Converts STAC-formatted dates to the format needed for the TimeSelector component.
 */
export const stacDatesToTimeSelector = ({ dates, handleClick }: stacDatesToTimeSelectorProps) => {
    if (dates === null) {
        log.error('stacDatesToTimeSelector: no dates passed in.');
        return [];
    }

    return dates.map((date) => {
        return {
            color: date.color,
            label: getFormattedDate(date.date),
            dateKey: date.id,
            onClick: () => handleClick(date),
            value: date.date.getTime()
        };
    });
};

/**
 * Handles users clicking on individual days in the "Observations over time" feature.
 * Sets the target date and active capture, and manages the selected feature in drawers.
 * Also fires the relevant analytics event.
 */
export const handleTimeSelectorDayClick = ({
    day,
    dispatch,
    targetId,
    trackDayClick,
    layerId
}: handleDayClickProps) => {
    // setting date to very end of the selected day
    // for consistency with global date, which must set to end of day
    const endOfDay = getDayEnd(day.date).getTime();

    const featurePolygon = polygon([day.coordinates]);
    const [lng, lat] = centroid(featurePolygon).geometry.coordinates;

    // For point drawers, clear highlights and reset the selected feature. This closes
    // the data drawer, revealing the floating summary
    if (layerId === MethaneLayerIds.areaEmissionRaster || layerId === MethaneLayerIds.plumeEmissionRate) {
        dispatch(resetSelectedFeatureParams());
        dispatch(removeAllMapFeatureHighlights());
    }
    // For targets, update selected feature and URL
    else if (layerId === MethaneLayerIds.targets) {
        dispatch(
            setSelectedFeatureParams({
                itemId: day.id,
                collectionId: day.collection || null,
                featureType: UrlFeatureTypes.Target,
                coordinates: [lng, lat],
                targetDate: formatDateForUrl(new Date(endOfDay)),
                targetId: targetId
            })
        );
    }

    // update target-specific date
    dispatch(setTargetDate({ date: endOfDay, targetId }));

    // set the capture that has been activated
    dispatch(
        setActiveCapture({
            targetId: targetId,
            itemId: day.id
        })
    );

    // fire off an analytics event to track feature usage statistics.
    trackDayClick(day, `${endOfDay}`);
};
