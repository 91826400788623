import React from 'react';

import { useTheme } from '../../hooks';
import { CalendarMonthIcon } from '../Icons';
import { Grid } from '../Layout';
import { TimeSelector } from '../TimeSelector';
import Typography from '../Typography';

import { DatePickerProps, LocaleDateVariant } from '../../types';
import { Select } from '../Select';
import { MenuItem } from '../MenuItem';
import { LocaleDate } from '../LocaleDate';

export const DatePicker = ({
    'data-testid': dataTestId,
    onClick,
    onChange,
    options,
    promptText,
    value,
    sx,
    platform,
    variant = LocaleDateVariant.shortMonthYear
}: DatePickerProps) => {
    const theme = useTheme();

    const data = options
        .map((option, index) => ({
            id: `date-option-${index}`,
            boxHeight: '35px',
            color: theme.palette.lightGray.main,
            dateKey: option.value,
            disabled: option.disabled,
            label: option.label,
            onClick: () => onClick(option.value),
            value: option.value
        }))
        .reverse();
    return (
        <Grid
            container
            sx={[
                (theme) => ({
                    ...theme.mixins.mapButton,
                    '&:hover, &:focus, &:focus-within': {},
                    padding: theme.spacing(2),
                    flexWrap: 'nowrap',
                    width: 'unset'
                }),
                ...(Array.isArray(sx) ? sx : [sx])
            ]}
            columnSpacing={1}
            flexDirection="row"
            alignItems="center"
            margin={0}
            className="msat_date-picker"
        >
            {/* icon */}
            <Grid item xs="auto">
                <CalendarMonthIcon
                    sx={{
                        fontSize: '48px'
                    }}
                />
            </Grid>
            {/* prompt */}
            <Grid item xs={0}>
                <Typography
                    sx={{
                        lineHeight: '1.25rem',
                        display: { xs: 'none', sm: 'block' }
                    }}
                >
                    {promptText}
                </Typography>
            </Grid>
            {/* date selector */}
            <Grid
                item
                xs
                // This is another magic number
                sx={{ display: { xs: 'none', sm: 'block' }, maxWidth: 'calc(100% - 160px) !important' }}
            >
                <TimeSelector
                    data-testid={dataTestId || 'date-picker'}
                    data={data}
                    centerLabel
                    platform={platform}
                    variant={variant}
                    value={value}
                    onChange={onChange}
                    layout="horizontal"
                    selectedValue={value}
                />
            </Grid>
            {/* use dropdown date selector on mobile */}
            <Grid item xs sx={{ display: { xs: 'block', sm: 'none' } }}>
                <Select
                    data-testid={'global-date-picker-dropdown'}
                    onChange={onChange}
                    variant="standard"
                    value={value}
                    sx={{
                        '& .MuiSelect-nativeInput': {
                            pointerEvents: 'none'
                        }
                    }}
                >
                    {data.map((item) => {
                        return (
                            <MenuItem key={item.value} value={item.value}>
                                <LocaleDate date={item.value} variant={variant} />
                            </MenuItem>
                        );
                    })}
                </Select>
            </Grid>
        </Grid>
    );
};
