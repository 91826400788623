import dynamic from 'next/dynamic';
import { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { black, colorArrayToRgb } from '@methanesat/colors';
import {
    Alert,
    Badge,
    BadgeProps,
    Box,
    Button,
    Checkbox,
    ChipSelect,
    ChipSelectLabel,
    ColorRangeIcon,
    ColorRangeIconScale,
    DispersedSourceIcon,
    FilterIcon,
    FormControlLabel,
    MarkdownFormatter,
    PlumeIcon,
    RegionalEmissionsIcon,
    styled,
    Theme,
    Typography,
    ZoomInfoBox,
    useTheme
} from '@methanesat/ui-components';

import { EMISSIONS_PRODUCT_UNITS, PLATFORM_RASTER_SIZE, interpolateColorScalePercentages } from '../../../consts';
import { SHOW_EMPTY_TARGET_MINI_LEGEND, SHOW_L3_OPTION } from '../../../environmentVariables';
import { useL3UnitsLabel, useTranslate } from '../../../hooks';
import {
    selectAreaEmissionsWithinZoom,
    selectEmissionsMapInfraOperatorFilter,
    selectEmissionsMapLayerConfigs,
    selectEmissionsMapInterface,
    selectInfrastructureWithinZoom,
    selectMethaneProduct,
    selectPlumeFluxWithinZoom,
    selectTargetWithinZoom,
    toggleAreaEmissionChip,
    toggleOgiFilterChip,
    togglePlumeEmissionChip,
    toggleTargetEmissionChip,
    selectPlatform
} from '../../../reducers';
import { AreaEmissionsProducts, MethaneLayerIds } from '../../../types';
import {
    analytics,
    ErrorBoundary,
    getColorRangeColors,
    getTargetTotalsColors,
    handleErrorBoundaryError
} from '../../../utils';
import { ColorThermometer } from '../../ColorThermometer';
import { InfrastructureOperatorFilter } from '../../MapControls';
import { AreaEmissionsProductToggle } from '../AreaEmissionsProductToggle';
import { AdvancedChipFunctions } from './AdvancedChipFunctions';
import { OgiChip } from './OgiChip';

import type { RootState } from '../../../store';
import { useAugmentLabels, generateColorStopLabels, getEmissionsThermometerColors } from './MiniLegendUtils';

interface MiniLegendProps {
    onOpenLegend: () => void;
    rightWidthOffset: number;
}

/**
 * Get the Icon for the color range
 * @param theme
 * @param enabled
 * @returns
 */
export const getColorRangeIcon = (theme: Theme, enabled: boolean, scale: ColorRangeIconScale) => {
    const colorRangeIconColors = getColorRangeColors(theme, enabled);
    return <ColorRangeIcon colors={colorRangeIconColors} scale={scale} />;
};

const defaultTargetFillColor = colorArrayToRgb(black);

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 2,
        top: 5,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px'
    }
}));

const DynamicHorizontalScroll = dynamic(
    () => import('@methanesat/ui-components').then((module) => module.HorizontalScroll),
    {
        ssr: false,
        loading: () => <p>Loading...</p>
    }
);

/**
 * The reduced version of the main legend. It allows the user to quickly understand the map
 * and to access some filtering features.
 */
const MiniLegend = ({ rightWidthOffset }: MiniLegendProps) => {
    const t = useTranslate();
    const dispatch = useDispatch();
    const theme = useTheme();

    const emissionsProduct = useSelector(selectMethaneProduct);

    // OGI Filter chip functions
    const isOgiOperatorFilterExpanded = useSelector(
        (state: RootState) => selectEmissionsMapInterface(state).ui.isOgiOperatorFilterExpanded
    );
    const handleFilterExpanded = (_event: SyntheticEvent, isExpanded: boolean) => {
        dispatch(toggleOgiFilterChip(isExpanded));
    };

    // Target emission expanded
    const isTargetEmissionChipExpanded = useSelector(
        (state: RootState) => selectEmissionsMapInterface(state).ui.isTargetEmissionsChipExpanded
    );
    const handleTargetEmissionChipExpanded = (_event: SyntheticEvent, isExpanded: boolean) => {
        dispatch(toggleTargetEmissionChip(isExpanded));
    };

    // Area emission expanded
    const isAreaEmissionsChipExpanded = useSelector(
        (state: RootState) => selectEmissionsMapInterface(state).ui.isAreaEmissionsChipExpanded
    );
    const handleAreaEmissionExpanded = (_event: SyntheticEvent, isExpanded: boolean) => {
        dispatch(toggleAreaEmissionChip(isExpanded));
    };

    // Plume chip expanded
    const isPlumeChipExpanded = useSelector(
        (state: RootState) => selectEmissionsMapInterface(state).ui.isPlumeEmissionsChipExpanded
    );
    const handlePlumeChipExpanded = (_event: SyntheticEvent, isExpanded: boolean) => {
        dispatch(togglePlumeEmissionChip(isExpanded));
    };

    const areaEmissionLayerEnabled = useSelector(
        (state: RootState) => selectEmissionsMapLayerConfigs(state)[MethaneLayerIds.areaEmissionRaster].enabled
    );
    const plumeEmissionLayerEnabled = useSelector(
        (state: RootState) => selectEmissionsMapLayerConfigs(state)[MethaneLayerIds.plumeEmissionRate].enabled
    );

    const areaEmissionLayerWithinZoom = useSelector(selectAreaEmissionsWithinZoom);
    const plumeEmissionLayerWithinZoom = useSelector(selectPlumeFluxWithinZoom);
    const infrastructureLayerWithinZoom = useSelector(selectInfrastructureWithinZoom);
    const targetLayerWithinZoom = useSelector(selectTargetWithinZoom);
    const selectedOperators = useSelector(selectEmissionsMapInfraOperatorFilter);

    const product = useSelector(selectMethaneProduct);
    const platform = useSelector(selectPlatform);

    const colorRangeIconColors = getColorRangeColors(theme, areaEmissionLayerEnabled, product, platform);
    const targetTotalsColors = getTargetTotalsColors(theme, targetLayerWithinZoom);
    const emissionsThermometerColors = getEmissionsThermometerColors(platform, product, colorRangeIconColors);

    const targetTooltip = `emissionsMapPage.miniLegend.${MethaneLayerIds.targets}.equivalencyTooltip.${platform}`;
    const l4Tooltip = `emissionsMapPage.miniLegend.${MethaneLayerIds.areaEmissionRaster}.equivalencyTooltip.${platform}`;

    const targetColorStopLabels = useAugmentLabels(
        generateColorStopLabels(platform, MethaneLayerIds.targets),
        EMISSIONS_PRODUCT_UNITS.target,
        targetTooltip
    );

    const l4EmissionsColorStopLabels = useAugmentLabels(
        generateColorStopLabels(platform, MethaneLayerIds.areaEmissionRaster),
        EMISSIONS_PRODUCT_UNITS.l4,
        l4Tooltip
    );

    const l3UnitsLabel = useL3UnitsLabel();

    // chip error boundary alert
    const ChipErrorBoundaryAlert = ({ resetErrorBoundary, id }: { id: string; resetErrorBoundary: () => void }) => {
        return (
            <>
                <Alert
                    sx={{
                        maxWidth: '100%',
                        fontSize: 10
                    }}
                    severity="error"
                >
                    {t('emissionsMapPage.error.general')}
                </Alert>
                <Button
                    color="error"
                    variant="text"
                    data-testid={`${id}-error-boundary-reset`}
                    onClick={resetErrorBoundary}
                >
                    {t('emissionsMapPage.error.retry')}
                </Button>
            </>
        );
    };

    // These are the elements to be rendered in different positions depending
    // on the screen size.  The elements will also appear and disappear at the
    // appropriate zoom levels
    const elements = [
        // regional emissions
        <span key="target-emissions">
            <ChipSelect
                data-testid="target-emissions"
                disabled={!targetLayerWithinZoom}
                expanded={isTargetEmissionChipExpanded}
                expandIconId="target-emissions-expand"
                label={
                    <ChipSelectLabel
                        icon={<ColorRangeIcon colors={targetTotalsColors} scale={ColorRangeIconScale.linear} />}
                    >
                        {t('emissionsMapPage.miniLegend.targets.mairTarget')}
                    </ChipSelectLabel>
                }
                onChange={handleTargetEmissionChipExpanded}
            >
                {!targetLayerWithinZoom && (
                    <ZoomInfoBox data-testid='zoom-info-box'>
                        <strong>{t('emissionsMapPage.miniLegend.zoomOut')}</strong>{' '}
                        {t('emissionsMapPage.miniLegend.showOnMap')}
                    </ZoomInfoBox>
                )}
                <Typography variant="body2" sx={{ marginY: 1 }}>
                    <MarkdownFormatter markdown={t(`emissionsMapPage.miniLegend.targets.description.${platform}`)} />
                </Typography>
                <ColorThermometer
                    colors={interpolateColorScalePercentages(targetColorStopLabels, platform)}
                    labels={targetColorStopLabels}
                />
                {SHOW_EMPTY_TARGET_MINI_LEGEND && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checkedIcon={
                                    <RegionalEmissionsIcon
                                        sx={{ outline: defaultTargetFillColor, transform: 'rotate(90deg)' }}
                                    />
                                }
                                checked={true}
                                data-testid={`defaultTarget-checkbox`}
                                disabled
                                name={'defaultTarget'}
                                sx={{
                                    height: 24,
                                    // Until we can select the checkboxes, make the hover
                                    // color transparent so it doesn't look selectable
                                    '&.MuiButtonBase-root.MuiCheckbox-root:hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                            />
                        }
                        key={'defaultTarget'}
                        label={t('emissionsMapPage.miniLegend.targets.pendingData')}
                        slotProps={{
                            typography: {
                                variant: 'body2',
                                // When the checkbox is disabled, do not gray out the text
                                sx: {
                                    '&.MuiFormControlLabel-label.Mui-disabled': { color: 'text.primary' }
                                }
                            }
                        }}
                    />
                )}
            </ChipSelect>
        </span>,
        // area emissions
        <span key="area-emissions">
            <ChipSelect
                data-testid="area-emissions"
                disabled={!areaEmissionLayerWithinZoom}
                expanded={isAreaEmissionsChipExpanded}
                expandIconId="area-emissions-expand"
                label={
                    <ChipSelectLabel
                        icon={
                            // Different icons depending on whether we are displaying L3 vs L4 data
                            product === AreaEmissionsProducts.l4 ? (
                                <DispersedSourceIcon
                                    colors={getColorRangeColors(theme, areaEmissionLayerEnabled, product)}
                                    sx={{ marginLeft: 1, marginTop: 1 }}
                                />
                            ) : (
                                <ColorRangeIcon colors={colorRangeIconColors} scale={ColorRangeIconScale.log} />
                            )
                        }
                    >
                        {t('emissionsMapPage.miniLegend.area-emission-raster.label')}
                    </ChipSelectLabel>
                }
                onChange={handleAreaEmissionExpanded}
            >
                {!areaEmissionLayerWithinZoom && (
                    <ZoomInfoBox data-testid='zoom-info-box'>
                        <strong>{t('emissionsMapPage.miniLegend.zoomIn')}</strong>{' '}
                        {t('emissionsMapPage.miniLegend.showOnMap')}
                    </ZoomInfoBox>
                )}
                <Typography variant="body2" paddingBottom={1}>
                    {t(`emissionsMapPage.miniLegend.area-emission-raster.${emissionsProduct}Description`, {
                        sideLength: PLATFORM_RASTER_SIZE[platform]
                    })}
                </Typography>
                {product === AreaEmissionsProducts.l4 && (
                    <ColorThermometer
                        accentuateLowestValue={false}
                        colors={emissionsThermometerColors}
                        labels={l4EmissionsColorStopLabels}
                    />
                )}
                {product === AreaEmissionsProducts.l3 && (
                    <ColorThermometer
                        accentuateLowestValue
                        colors={emissionsThermometerColors}
                        labels={[
                            {
                                percent: 100,
                                label: t('emissionsMapPage.miniLegend.generalEmissions.orMore', {
                                    value: `2280 ${l3UnitsLabel}`
                                })
                            },
                            { percent: 32, label: '2008' },
                            { percent: 10, label: '1920' },
                            {
                                percent: 0,
                                label: t('emissionsMapPage.miniLegend.generalEmissions.orLess', { value: '1880' })
                            }
                        ]}
                    />
                )}
                <AdvancedChipFunctions
                    label={
                        areaEmissionLayerEnabled
                            ? t('emissionsMapPage.miniLegend.hide')
                            : t('emissionsMapPage.miniLegend.show')
                    }
                    layerEnabled={areaEmissionLayerEnabled}
                    layerId={MethaneLayerIds.areaEmissionRaster}
                    analyticsEvent={analytics.toggleAreaEmissionData.bind(analytics)}
                >
                    {SHOW_L3_OPTION ? (
                        <Box my={1}>
                            <AreaEmissionsProductToggle location="minilegend" />
                        </Box>
                    ) : undefined}
                </AdvancedChipFunctions>
            </ChipSelect>
        </span>,
        // plume emissions
        <span key="point-source-toggle-chip">
            <ChipSelect
                data-testid="point-source-toggle-chip"
                disabled={!plumeEmissionLayerWithinZoom}
                expanded={isPlumeChipExpanded}
                expandIconId="plume-emissions-expand"
                label={
                    <ChipSelectLabel
                        icon={
                            <PlumeIcon
                                sx={{
                                    height: 24,
                                    marginTop: '5px'
                                }}
                                colors={getColorRangeColors(theme, plumeEmissionLayerEnabled)}
                            />
                        }
                    >
                        {t('emissionsMapPage.miniLegend.plume-emission-rate.label')}
                    </ChipSelectLabel>
                }
                onChange={handlePlumeChipExpanded}
            >
                {!plumeEmissionLayerWithinZoom && (
                    <ZoomInfoBox data-testid='zoom-info-box'>
                        <strong>{t('emissionsMapPage.miniLegend.zoomIn')}</strong>{' '}
                        {t('emissionsMapPage.miniLegend.showOnMap')}
                    </ZoomInfoBox>
                )}
                {/* FormGroup aligns the elements vertically */}
                <Typography variant="body2">
                    <MarkdownFormatter markdown={t('emissionsMapPage.miniLegend.plume-emission-rate.description')} />
                </Typography>
                <AdvancedChipFunctions
                    label={
                        plumeEmissionLayerEnabled
                            ? t('emissionsMapPage.miniLegend.hide')
                            : t('emissionsMapPage.miniLegend.show')
                    }
                    layerEnabled={plumeEmissionLayerEnabled}
                    layerId={MethaneLayerIds.plumeEmissionRate}
                    analyticsEvent={analytics.togglePlumeEmissionData.bind(analytics)}
                />
            </ChipSelect>
        </span>,
        // ogi type filter chip
        <span key="ogi-type-chip">
            <OgiChip disabled={!infrastructureLayerWithinZoom} />
        </span>,
        /** OGI operator filter Chip */
        <span key="filter-ogi-chip">
            <ChipSelect
                data-testid="filter-ogi-chip"
                disabled={!infrastructureLayerWithinZoom}
                expandIconId="ogi-operator-filter-expand"
                expanded={isOgiOperatorFilterExpanded}
                label={
                    <ChipSelectLabel
                        icon={
                            <StyledBadge badgeContent={selectedOperators.length} color="primary">
                                <FilterIcon />
                            </StyledBadge>
                        }
                    >
                        {t('emissionsMapPage.miniLegend.ogiFilter.label')}
                    </ChipSelectLabel>
                }
                onChange={handleFilterExpanded}
            >
                {!isOgiOperatorFilterExpanded ? null : (
                    <ErrorBoundary
                        onError={handleErrorBoundaryError('MiniLegend - OGI operator filter')}
                        fallbackRender={({ resetErrorBoundary }) => (
                            <ChipErrorBoundaryAlert id="ogi-operator-filter" resetErrorBoundary={resetErrorBoundary} />
                        )}
                    >
                        {!infrastructureLayerWithinZoom && (
                            <ZoomInfoBox data-testid='zoom-info-box'>
                                <strong>{t('emissionsMapPage.miniLegend.zoomIn')}</strong>{' '}
                                {t('emissionsMapPage.miniLegend.toActivate')}
                            </ZoomInfoBox>
                        )}
                        <InfrastructureOperatorFilter
                            GridProps={{ paddingRight: 0, paddingBottom: 1 }}
                            isSmall={true}
                            rightWidthOffset={0}
                        />
                    </ErrorBoundary>
                )}
            </ChipSelect>
        </span>
    ].filter((element) => !!element);
    return (
        /** Horizontally scroll elements.
         * pointerEvents is auto for the elements that are not grids.
         * This allows the user to click through the row containing the chips.
         */
        <Box
            sx={{
                '& :not(.MuiGrid-root)': { pointerEvents: 'auto' },
                width: 'fit-content',
                maxWidth: `calc(100% + ${rightWidthOffset}px)`
            }}
        >
            {/* The mini-legend must change size when drawers open/close, so we use
                enableResizeObserver=true to allow dynamic styling of the scroll buttons. */}
            <DynamicHorizontalScroll
                elements={elements}
                enableResizeObserver={true}
                ariaLabelRightScroll={t('controls.scrollRight')}
                ariaLabelLeftScroll={t('controls.scrollRight')}
            />
        </Box>
    );
};

export default MiniLegend;
