import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Box,
    ColorRangeIcon,
    ColorRangeIconScale,
    DispersedSourceIcon,
    Grid,
    LocaleDateVariant,
    Typography,
    useTheme
} from '@methanesat/ui-components';

import { METHANESAT_FAQ_URL, PLATFORM_RASTER_SIZE } from '../../../../../consts';
import { SHOW_SHAREABLE_FEATURE_LINK } from '../../../../../environmentVariables';
import {
    useAreaEmissionsUnitsLabel,
    useGetHistoricalSamplesForTarget,
    useLengthUnitsLabel,
    useTranslate
} from '../../../../../hooks';
import { selectMethaneProduct, selectPlatform, selectTargetDates } from '../../../../../reducers';
import {
    AreaEmissionDrawerProps,
    AreaEmissionsProducts,
    MethaneLayerIds,
    STACCalendarData,
    UrlFeatureTypes
} from '../../../../../types';
import {
    analytics,
    formatCoordinates,
    formatMeasurementTime,
    getColorRangeColors,
    getFeatureLinkLabel
} from '../../../../../utils';
import { DrawerHeader } from '../../Headers';
import { DataRowsModalBody, FeatureLink } from '../../Layout';
import { EmissionsHeadline } from './EmissionsHeadline';
import { MoreInfoSection } from './MoreInfoSection';
import { handleTimeSelectorDayClick, stacDatesToTimeSelector } from './helpers';
import dynamic from 'next/dynamic';

/**
 * next/dynamic here allows us to use enableResizeObserver within the HorizontalScroll component.
 * enableResizeObserver helps with right scrolling issues within the drawer.
 */
const DynamicTimeSelector = dynamic(() => import('@methanesat/ui-components').then((module) => module.TimeSelector), {
    ssr: false,
    loading: () => <p>Loading...</p>
});

// TODO:  DP-1892 MethaneRasterDataDrawer and PlumeEmissionsDrawer are nearly identical and can
// likely be refactored to consolidate code
export const MethaneRasterDataDrawer = ({ info }: AreaEmissionDrawerProps) => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const { coordinate, object } = info;
    const targetId = info.layer.targetId;
    const selectorDates = useGetHistoricalSamplesForTarget(targetId) ?? [];

    const id = object.id;
    const collection = object.properties.collectionId;
    const collectionEndTime = object.properties.collectionEndTime;
    const collectionStartTime = object.properties.collectionStartTime;
    const platform = useSelector(selectPlatform);
    const label = getFeatureLinkLabel(platform, id, object.properties.sceneId);
    const targetDates = useSelector(selectTargetDates);
    const layerId = MethaneLayerIds.areaEmissionRaster;

    const product = useSelector(selectMethaneProduct);
    useEffect(() => {
        analytics.openAreaEmissionDetails({
            emissionRate: info.object.properties.methane,
            /**
             * Likely unnecessary to find centroid.
             * For permian latitudes, if area is approx 1 km sq, there is no
             * difference in formatted coordinates of the centroid (calculated by turf.js)
             * and the coordinates of the point the user clicked
             */
            latitude: info.coordinate?.[1],
            longitude: info.coordinate?.[0]
        });
    }, [info.object, info.coordinate]);
    const t = useTranslate();

    // For drawer header
    const drawerTitle = t(`emissionsMapPage.mapModals.emissions.areaTitle.${product}`) || '';
    const iconColors = getColorRangeColors(theme, true, product);

    const descriptionText = t(`emissionsMapPage.mapModals.emissions.areaDescription.${product}`);

    const normalizedMethaneKgHrKm2 = info.object.properties.methane / Math.pow(PLATFORM_RASTER_SIZE[platform], 2);

    const lengthUnitsLabel = useLengthUnitsLabel();
    const detailDataRows = [
        ...(info.coordinate
            ? [
                  {
                      label: t('emissionsMapPage.mapModals.common.dataTable.coordinatesLabel'),
                      value: formatCoordinates(info.coordinate)
                  }
              ]
            : []),
        {
            label: t('emissionsMapPage.mapModals.emissions.measurementTimeLabel'),
            value: formatMeasurementTime(collectionStartTime, collectionEndTime)
        },
        {
            label: t('emissionsMapPage.mapModals.common.dimensionsLabel'),
            value: `~ ${PLATFORM_RASTER_SIZE[platform]} ${lengthUnitsLabel} x ${PLATFORM_RASTER_SIZE[platform]} ${lengthUnitsLabel}`
        }
    ];
    const unitsLabel = useAreaEmissionsUnitsLabel(product);

    const trackDayClick = (day: STACCalendarData, timestampString: string) => {
        analytics.clickDispersedDrawerObservation({
            itemId: day.id,
            platform: day.platform,
            emissionRate: day.totalKgHr ?? NaN,
            label: timestampString
        });
    };
    return (
        <>
            <Box>
                <DrawerHeader
                    title={drawerTitle}
                    icon={
                        // Different icons depending on whether we are displaying L3 vs L4 data
                        product === AreaEmissionsProducts.l4 ? (
                            <DispersedSourceIcon colors={iconColors} sx={{ marginTop: 1 }} />
                        ) : (
                            <ColorRangeIcon colors={iconColors} scale={ColorRangeIconScale.log} />
                        )
                    }
                />
                {/* Emissions & confidence */}
                <Grid container marginTop={5} marginBottom={3} rowGap={2}>
                    <Grid item xs={12}>
                        <EmissionsHeadline
                            emissions={normalizedMethaneKgHrKm2}
                            units={unitsLabel}
                            data-testid="raster-emissionsheadline-value"
                        />
                    </Grid>
                    {descriptionText && (
                        <Grid item xs={12}>
                            <Typography variant="body1">{descriptionText}</Typography>
                        </Grid>
                    )}
                </Grid>

                {/* At a glance */}
                <Grid container paddingTop={3} paddingBottom={1}>
                    <Typography variant="h4">{t('emissionsMapPage.mapModals.common.dataTableTitle')}</Typography>
                </Grid>
                <DataRowsModalBody dataRows={detailDataRows} />

                {/* Observations over time */}
                <Grid container marginTop={3} marginBottom={3} rowGap={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4">{t('emissionsMapPage.mapModals.common.overTimeTitle')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DynamicTimeSelector
                            data-testid="time-selector-day-picker"
                            data={stacDatesToTimeSelector({
                                dates: selectorDates,
                                handleClick: (day: STACCalendarData) => {
                                    handleTimeSelectorDayClick({
                                        day,
                                        dispatch,
                                        targetId,
                                        layerId,
                                        trackDayClick
                                    });
                                }
                            })}
                            enableResizeObserver
                            variant={LocaleDateVariant.day}
                            selectedValue={collectionStartTime}
                            boxHeight="50px"
                        />
                    </Grid>
                </Grid>

                <MoreInfoSection
                    header={t('emissionsMapPage.mapModals.dispersed.moreInfo.header')}
                    content={t('emissionsMapPage.mapModals.dispersed.moreInfo.content', {
                        link: METHANESAT_FAQ_URL,
                        sideLength: PLATFORM_RASTER_SIZE[platform]
                    })}
                />
                {SHOW_SHAREABLE_FEATURE_LINK && coordinate && (
                    <Grid container marginTop={3}>
                        {/* title */}
                        <Grid item xs={12} marginBottom={2}>
                            <Typography variant="h5">
                                {t(`emissionsMapPage.mapModals.linksToFeatures.title.${layerId}`)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FeatureLink
                                collectionId={collection}
                                coordinates={coordinate}
                                date={collectionEndTime}
                                featureType={UrlFeatureTypes.AreaEmission}
                                itemId={id}
                                label={label}
                                layerId={layerId}
                                platform={platform}
                                targetDate={targetDates[targetId]}
                                targetId={targetId}
                            />
                        </Grid>
                    </Grid>
                )}
            </Box>
        </>
    );
};
